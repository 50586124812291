import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BookAnAppointmentMainComponent from '../BookAnAppointment/BookAnAppointment'
import emailjs from 'emailjs-com';
import parse from 'html-react-parser'
import { Helmet } from "react-helmet";

// IMAGES
import ImgBanner from '../../Images/ImgBanner.jpeg'
import LogoBanner from '../../Images/LogoBanner.png'
import BeforeAndAfterImg from '../../Images/BeforeAndAfterImg.png'
import chimneyinspection from '../../Images/chimneyinspection.jpeg'
import chimneysweep from '../../Images/chimneysweep.jpeg'
import chimneyrepair from '../../Images/chimneyrepair.jpeg'
import fireplacebanner from '../../Images/fireplacebanner.jpeg'
import Book from '../../Images/Book.jpeg'
import './ServicePage.scss';

import AreaData from './AreaData'


const ServicePage = () => {
    const [Content, setContent] = useState(null);
    const [Menu, setMenu] = useState(false);
    const [BookAnAppointment, setBookAnAppointment] = useState(false);
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const [FormSubmited, setFormSubmited] = useState(false);
    const [CustInfo, setCustInfo] = useState({
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Service: 'Chimney Inspection Lvl 1 | 99$',
        Date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
        Time: '8:00AM-11:00AM',
        Address1: '',
        Address2: '',
        City: '',
        State: 'AL',
        Zipcode: '',
        Note: ''
    });

    const location = useLocation().pathname
    const navigate = useNavigate()

    function sendEmail(e) {
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_h897je5', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setFormSubmiting(false)
                setFormSubmited(true)
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }


    useEffect(() => {
        setContent(AreaData.find(a => a.Path == location));
        console.log(AreaData.find(a => a.Path == location));
        console.log(Content);
    }, []);

    return (
        <div className='ServicePage'>
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>{Content ? Content.Des + " " + '951-379-4048' : `Top Rated Chimney Sweep Riverside | AAA Chimney Sweep Cleaning & Services | 951-379-4048`}</title>
                {/* <!-- META --> */}
                <meta property="og:url" content={`https://www.riverside-aaachimneysweep.com/${Content ? Content.Path : ""}`} data-react-helmet="true" />
                <meta name="description" content={`${Content ? Content.Des : "Top Rated Chimney Sweep Riverside | AAA Chimney Sweep Cleaning & Services"} - Provides local Chimney Sweep, inspection, cleaning, repair and maintenance services. Call us today at 951-379-4048.`} data-react-helmet="true" />
                <meta property="og:title" content={`${Content ? Content.Des : "Top Rated Chimney Sweep Riverside | AAA Chimney Sweep Cleaning & Services"}`} data-react-helmet="true" />
                <meta property="og:description" content={`${Content ? Content.Des : "Top Rated Chimney Sweep Riverside | AAA Chimney Sweep Cleaning & Services"}  - Site Map provides local Chimney Sweep, cleaning, repair and maintenance services. Call us today at 951-379-4048.`} data-react-helmet="true" />
                <meta property="og:site_name" content={`${Content ? Content.Des : "Top Rated Chimney Sweep Riverside | AAA Chimney Sweep Cleaning & Services"} - Site Map`} data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap" data-react-helmet="true" />
            </Helmet>
            <div className='Header'>
                <div className="HeaderBottom">
                    <div className="HeaderInfo">
                        <a href="/">
                            <img src={LogoBanner} alt="" />
                        </a>
                    </div>
                    <div className="HeaderMenu">
                        <a href="tel:‪951-379-4048‬">‪951-379-4048‬</a>
                        {Menu ?
                            <img onClick={() => setMenu(false)} src="https://cdn-icons-png.flaticon.com/512/2976/2976286.png" alt="AAA Chimney Sweep Menu" />
                            :
                            <img onClick={() => { setMenu(true) }} src="https://cdn-icons-png.flaticon.com/512/2976/2976215.png" alt="AAA Chimney Sweep Menu" />
                        }
                    </div>
                    {
                        Menu ?
                            <div className="HeaderMainMenu">
                                <a onClick={() => setMenu(false)} href="#mainbanner" >Home</a>
                                <a onClick={() => setMenu(false)} href="#services" >Services</a>
                                <a onClick={() => setMenu(false)} href="#whyus" >About Us</a>
                                <a onClick={() => setMenu(false)} href="#footer" >Contact Us</a>
                                <div className="HeaderMainMenuMore">
                                    <a onClick={() => {
                                        setMenu(false)
                                        setBookAnAppointment(true)
                                    }}>Book An Appointment <img src="https://cdn-icons-png.flaticon.com/512/7322/7322265.png" alt="AAA Chimney Sweep Book An Appointment" /></a>
                                    <a href="tel:‪951-379-4048‬">CALL NOW! <img src="https://cdn-icons-png.flaticon.com/512/597/597177.png" alt="AAA Chimney Sweep Phone Number" /></a>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
            <div className="WebSite">
                <div className="WebSiteBanner">
                    {Content ? parse(Content.Content) : null}
                    <a href=""></a>
                </div>
                <div id='footer' className="Footer">
                    <div className="FooterFormMain">
                        <div className="FooterFormTitle">
                            <h1>CONTACT US FOR MORE DETAILS</h1>
                        </div>
                        <div className="FooterForm">

                            <form className="contact-form" onSubmit={sendEmail}>
                                <h3>Name</h3>
                                <input type='text' name="Name" />
                                <h3>Phone</h3>
                                <input type='tel' name="Phone" />
                                <h3>Location (Address)</h3>
                                <input type='text' name="Location" />
                                <h3>Message</h3>
                                <input type='text' name="Message" />

                                <input type="hidden" name="Company_From" defaultValue={`AAA Chimney Sweep`} />

                                {
                                    FormSubmiting ?
                                        <button class="buttonload">
                                            <i class="fa fa-refresh fa-spin"></i>Loading
                                        </button>
                                        :

                                        <input type="submit" value="SEND" />
                                }
                            </form>
                        </div>
                    </div>

                </div>
                <div className="ServicesMore">

                    <h1>MORE OF OUR SERVICES:</h1>
                    <div className="ServicesMoreBoxMain">
                        <div className="ServicesMoreBoxMainLeft">
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Cap Repair Riverside, CA" />
                                <a href="/Chimney-Cap-Repair">Chimney Cap Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Cleaning Riverside, CA" />
                                <a href="/Chimney-Cleaning">Chimney Cleaning Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Construction Riverside, CA" />
                                <a href="/Chimney-Construction">Chimney Construction Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Crown Repair Riverside, CA" />
                                <a href="/Chimney-Crown-Repair">Chimney Crown Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Flue Installation Riverside, CA" />
                                <a href="/Chimney-Flue-Installation">Chimney Flue Installation Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Flue Repair Riverside, CA" />
                                <a href="/Chimney-Flue-Repair">Chimney Flue Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Inspection Riverside, CA" />
                                <a href="/Chimney-Inspection">Chimney Inspection Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Installation Riverside, CA" />
                                <a href="/Chimney-Installation">Chimney Installation Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Insulation Riverside, CA" />
                                <a href="/Chimney-Insulation">Chimney Insulation Riverside, CA</a>
                            </div>
                        </div>
                        <div className="ServicesMoreBoxMainMiddle">
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Cracks Repair Riverside, CA" />
                                <a href="/Chimney-Cracks-Repair">Chimney Cracks Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Firebox Paint Riverside, CA" />
                                <a href="/Chimney-Firebox-Paint">Chimney Firebox Paint Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Firebox Cleaining Riverside, CA" />
                                <a href="/Chimney-Firebox-Cleaining">Chimney Firebox Cleaining Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Firebox Inspection Riverside, CA" />
                                <a href="/Chimney-Firebox-Inspection">Chimney Firebox Inspection Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Best Company Riverside, CA" />
                                <a href="/Chimney-Best-Company">Chimney Best Company Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Firebox Inspection Riverside, CA" />
                                <a href="/Firebox-Inspection">Firebox Inspection Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Firebox Sweeping Riverside, CA" />
                                <a href="/Firebox-Sweeping">Firebox Sweeping Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Fireplace Inspection Riverside, CA" />
                                <a href="/Fireplace-Inspection">Fireplace Inspection Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Firepalce Repair Riverside, CA" />
                                <a href="/Firepalce-Repair">Firepalce Repair Riverside, CA</a>
                            </div>
                        </div>
                        <div className="ServicesMoreBoxMainRight">
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Liner Repair Riverside, CA" />
                                <a href="/Chimney-Liner-Repair">Chimney Liner Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Maintenance Riverside, CA" />
                                <a href="/Chimney-Maintenance">Chimney Maintenance Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Pointing Riverside, CA" />
                                <a href="/Chimney-Pointing">Chimney Pointing Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Rain Cap Installation Riverside, CA" />
                                <a href="/Chimney-Rain Cap-Installation">Chimney Rain Cap Installation Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Repair Riverside, CA" />
                                <a href="/Chimney-Repair">Chimney Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Restoration Riverside, CA" />
                                <a href="/Chimney-Restoration">Chimney Restoration Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Flashing Repair Riverside, CA" />
                                <a href="/Chimney-Flashing-Repair">Chimney Flashing Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Liner Installation Riverside, CA" />
                                <a href="/Chimney-Liner-Installation">Chimney Liner Installation Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Damper Repair Riverside, CA" />
                                <a href="/Chimney-Damper-Repair">Chimney Damper Repair Riverside, CA</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div id='footer' className="Footer">
                <div className="FooterFormMain">
                    <div className="FooterFormTitle">
                        <h1>CONTACT US FOR MORE DETAILS</h1>
                    </div>
                    <div className="FooterForm">

                        <form className="contact-form" onSubmit={sendEmail}>
                            <h3>Name</h3>
                            <input type='text' name="Name" />
                            <h3>Phone</h3>
                            <input type='tel' name="Phone" />
                            <h3>Location (Address)</h3>
                            <input type='text' name="Location" />
                            <h3>Message</h3>
                            <input type='text' name="Message" />

                            <input type="hidden" name="Company_From" defaultValue={`AAA Chimney Sweep`} />

                            {
                                FormSubmiting ?
                                    <button class="buttonload">
                                        <i class="fa fa-refresh fa-spin"></i>Loading
                                    </button>
                                    :

                                    <input type="submit" value="SEND" />
                            }
                        </form>
                    </div>
                </div>
                <div className="FooterContactUs">
                    <div className="FooterContactUsTitle">
                        <h2>CONTACT DETAILS</h2>
                        <h3>If you have any questions at all, please feel free to contact us. We are available via phone or email. You can also use a quick contact form below or visit us personally.</h3>
                    </div>
                    <div className="FooterContactUsInfo">
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/597/597177.png" alt={`AAA Chimney Sweep Phone Number ‪951-379-4048‬`} />
                            <h2>Phone Number: <a href={`tel:‪951-379-4048‬`}>‪951-379-4048‬</a></h2>
                        </div>
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/646/646135.png" alt="AAA Chimney Sweep Email | info@riverside-aaachimneysweep.coml" />
                            <h2>Email: <a href="mailto:info@riverside-aaachimneysweep.com">info@riverside-aaachimneysweep.com</a></h2>
                        </div>
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/1006/1006771.png" alt="AAA Chimney Sweep Website | www.riverside-aaachimneysweep.com" />
                            <h2>Website: <a href="https://www.riverside-aaachimneysweep.com/">www.riverside-aaachimneysweep.com</a></h2>
                        </div>

                    </div>
                    <div className="FooterContactUsAbout">
                        <h2>
                            Our Customers Rates: 5.0 Stars
                        </h2>
                        <h3>
                            © Copyright 2022 AAA Chimney Sweep. All Right Reserved. <span onClick={() => navigate('/sitemap')}>Sitemap</span>
                        </h3>
                    </div>
                </div>

            </div>
            {
                !BookAnAppointment ?
                    <div className="CallNow">
                        {/* <h1>CALL NOW !</h1> */}
                        <a href="tel:‪951-379-4048‬">CALL NOW !</a>
                    </div>
                    :
                    null
            }
            {
                !BookAnAppointment ?
                    <div className="BookAnAppointmentButton" onClick={() => setBookAnAppointment(true)}>
                        <h1>BOOK ONLINE</h1>
                    </div>
                    :
                    null
            }
            {
                BookAnAppointment ?
                    <BookAnAppointmentMainComponent setBookAnAppointment={setBookAnAppointment} CustInfo={CustInfo} />
                    :
                    null}
        </div>
    );
}

export default ServicePage;
