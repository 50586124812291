import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from '../src/Public/App';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
   window.scrollTo(0, 0)
  }, [pathname]);

  return null;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <ScrollToTop />

      <App />
    </Router>
  </React.StrictMode>,
);

