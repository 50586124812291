import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from 'react-router-dom'
import BookAnAppointmentMainComponent from '../BookAnAppointment/BookAnAppointment'
import './SiteMap.scss';
import ImgBanner from '../../Images/ImgBanner.jpeg'
import AreaData from '../ServicePage/AreaData'
import emailjs from 'emailjs-com';

const SiteMap = () => {
    const [city, setCity] = useState({});
    const [Menu, setMenu] = useState(false);
    const [BookAnAppointment, setBookAnAppointment] = useState(false);
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const [FormSubmited, setFormSubmited] = useState(false);
    const [CustInfo, setCustInfo] = useState({
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Service: 'Chimney Inspection Lvl 1 | 99$',
        Date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
        Time: '8:00AM-11:00AM',
        Address1: '',
        Address2: '',
        City: '',
        State: 'AL',
        Zipcode: '',
        Note: ''
    });

    const location = useLocation().pathname
    const navigate = useNavigate()

    function sendEmail(e) {
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_h897je5', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setFormSubmiting(false)
                setFormSubmited(true)
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }

    useEffect(() => {
        // console.log(AreaData)
    }, []);

    return (
        <div className='SiteMap'>
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>{`The Best Chimney Sweep - Site Map | AAA Chimney Sweep Cleaning`}</title>
                {/* <!-- META --> */}
                <meta property="og:url" content={`https://www.riverside-aaachimneysweep.com/sitemap`} data-react-helmet="true" />
                <meta name="description" content={`AAA Chimney Sweep - Site Map provides local Chimney Sweep, inspection, cleaning, repair and maintenance services. Call us today at 951-379-4048.`} data-react-helmet="true" />
                <meta property="og:title" content={`The Best Chimney Sweep - Site Map | AAA Chimney Sweep`} data-react-helmet="true" />
                <meta property="og:description" content={`AAA Chimney Sweep  - Site Map provides local Chimney Sweep, cleaning, repair and maintenance services. Call us today at 951-379-4048.`} data-react-helmet="true" />
                <meta property="og:site_name" content={`AAA Chimney Sweep - Site Map`} data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap" data-react-helmet="true" />
            </Helmet>
            <div className='Header'>
                <div className="HeaderBottom">
                    <div className="HeaderInfo">
                        <h1><span>AAA </span>CHIMNEY SWEEP</h1>
                    </div>
                    <div className="HeaderMenu">
                        <a href="tel:‪951-379-4048‬">‪951-379-4048‬</a>
                        {Menu ?
                            <img onClick={() => setMenu(false)} src="https://cdn-icons-png.flaticon.com/512/2976/2976286.png" alt="Chimney Sweep Cleaning Near Me" />
                            :
                            <img onClick={() => { setMenu(true) }} src="https://cdn-icons-png.flaticon.com/512/2976/2976215.png" alt="Chimney Sweep Cleaning Near Me" />
                        }
                    </div>
                    {
                        Menu ?
                            <div className="HeaderMainMenu">
                                <a onClick={() => setMenu(false)} href="#mainbanner" >Home</a>
                                <a onClick={() => setMenu(false)} href="#services" >Services</a>
                                <a onClick={() => setMenu(false)} href="#whyus" >About Us</a>
                                <a onClick={() => setMenu(false)} href="#footer" >Contact Us</a>
                                <div className="HeaderMainMenuMore">
                                    <a onClick={() => {
                                        setMenu(false)
                                        setBookAnAppointment(true)
                                    }}>Book An Appointment <img src="https://cdn-icons-png.flaticon.com/512/7322/7322265.png" alt="Book An Appointment" /></a>
                                    <a href="tel:‪951-379-4048‬">CALL NOW! <img src="https://cdn-icons-png.flaticon.com/512/597/597177.png" alt="call now" /></a>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                {
                    BookAnAppointment ?
                        <BookAnAppointmentMainComponent setBookAnAppointment={setBookAnAppointment} CustInfo={CustInfo} />
                        :
                        null
                }
            </div>
            <div className="WebSite">
                <div className="MainBanner">
                    <div className="MainBannerInfo">
                        <h1>
                            SITEMAP
                        </h1>
                        <a href="tel:‪951-379-4048‬">‪951-379-4048‬</a>
                    </div>
                    <div className="MainBannerImg">
                        <div className="MainBannerImgDark"></div>
                        <img src={ImgBanner} alt="" />
                    </div>
                </div>
                <div className="SiteMapList">
                    <ul>
                        <li><a href="/">Home Page</a></li>
                    </ul>
                    {AreaData.map((A, index) =>
                        <ul key={index}>
                            <li><a href={`${A.Path}`}>{A.Title}</a></li>
                        </ul>
                    )}
                </div>
            </div>
            <div className="Footer">
                <div className="FooterFormMain">
                    <div className="FooterFormTitle">
                        <h1>CONTACT US FOR MORE DETAILS</h1>
                    </div>
                    <div className="FooterForm">

                        <form className="contact-form" onSubmit={sendEmail}>
                            <h3>Name</h3>
                            <input type='text' name="Name" />
                            <h3>Phone</h3>
                            <input type='tel' name="Phone" />
                            <h3>Location (Address)</h3>
                            <input type='text' name="Location" />
                            <h3>Message</h3>
                            <input type='text' name="Message" />

                            <input type="hidden" name="Company_From" defaultValue={`AAA Chimney Sweep`} />

                            {
                                FormSubmiting ?
                                    <button class="buttonload">
                                        <i class="fa fa-refresh fa-spin"></i>Loading
                                    </button>
                                    :

                                    <input type="submit" value="SEND" />
                            }
                        </form>
                    </div>
                </div>
                <div className="FooterContactUs">
                    <div className="FooterContactUsTitle">
                        <h2>CONTACT DETAILS</h2>
                        <h3>If you have any questions at all, please feel free to contact us. We are available via phone or email. You can also use a quick contact form below or visit us personally.</h3>
                    </div>
                    <div className="FooterContactUsInfo">
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/597/597177.png" alt="AAA Chimney Sweep cleaning phone number" />
                            <h2>Phone Number: <a href={`tel:‪951-379-4048‬`}>‪951-379-4048‬</a></h2>
                        </div>
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/646/646135.png" alt="supet Chimney Sweep email" />
                            <h2>Email: <a href="mailto:info@riverside-aaachimneysweep.com">info@riverside-aaachimneysweep.com</a></h2>
                        </div>
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/1006/1006771.png" alt="AAA Chimney Sweep website" />
                            <h2>Website: <a href="https://www.riverside-aaachimneysweep.com/">www.SuperShimneysSweep.com</a></h2>
                        </div>
                    </div>
                    <div className="FooterContactUsAbout">
                        <h2>
                            Our Customers Rates: 5.0 Stars 
                        </h2>
                        <h3>
                            © Copyright 2022 MR. Chimney Sweep. All Right Reserved. Sitemap
                        </h3>
                    </div>
                </div>

            </div>
            {
                !BookAnAppointment ?
                    <div className="CallNow">
                        <h1>CALL NOW !</h1>
                    </div>

                    :
                    null
            }
            {
                !BookAnAppointment ?
                    <div className="BookAnAppointmentButton" onClick={() => setBookAnAppointment(true)}>
                        <h1>BOOK ONLINE</h1>
                    </div>
                    :
                    null
            }
        </div>
    );
}

export default SiteMap;
