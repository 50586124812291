import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BookAnAppointmentMainComponent from '../BookAnAppointment/BookAnAppointment'
import emailjs from 'emailjs-com';

// IMAGES
import ImgBanner from '../../Images/ImgBanner.jpeg'
import img1 from '../../Images/img1.JPG'
import img2 from '../../Images/img2.JPG'
import img4 from '../../Images/img4.JPG'
import img5 from '../../Images/img5.JPG'
import LogoBanner from '../../Images/LogoBanner.png'
import BeforeAndAfterImg from '../../Images/BeforeAndAfterImg.png'
import chimneyinspection from '../../Images/chimneyinspection.jpeg'
import chimneysweep from '../../Images/chimneysweep.jpeg'
import chimneyrepair from '../../Images/chimneyrepair.jpeg'
import fireplacebanner from '../../Images/fireplacebanner.jpeg'
import Book from '../../Images/Book.jpeg'
import './HomePage.scss';

const HomePage = () => {
    const [Menu, setMenu] = useState(false);
    const [BookAnAppointment, setBookAnAppointment] = useState(false);
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const [FormSubmited, setFormSubmited] = useState(false);
    const [CustInfo, setCustInfo] = useState({
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Service: 'Chimney Inspection Lvl 1 | 99$',
        Date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
        Time: '8:00AM-11:00AM',
        Address1: '',
        Address2: '',
        City: '',
        State: 'AL',
        Zipcode: '',
        Note: ''
    });

    const location = useLocation().pathname
    const navigate = useNavigate()

    function sendEmail(e) {
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_h897je5', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setFormSubmiting(false)
                setFormSubmited(true)
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className='HomePage'>
            <div className='Header'>
                <div className="HeaderBottom">
                    <div className="HeaderInfo">
                        <a href="/">
                            <img src={LogoBanner} alt="" />
                        </a>
                    </div>
                    <div className="HeaderMenu">
                        <a href="tel:951-379-4048">951-379-4048</a>
                        {Menu ?
                            <img onClick={() => setMenu(false)} src="https://cdn-icons-png.flaticon.com/512/2976/2976286.png" alt="AAA Chimney Sweep Menu" />
                            :
                            <img onClick={() => { setMenu(true) }} src="https://cdn-icons-png.flaticon.com/512/2976/2976215.png" alt="AAA Chimney Sweep Menu" />
                        }
                    </div>
                    {
                        Menu ?
                            <div className="HeaderMainMenu">
                                <a onClick={() => setMenu(false)} href="#mainbanner" >Home</a>
                                <a onClick={() => setMenu(false)} href="#services" >Services</a>
                                <a onClick={() => setMenu(false)} href="#whyus" >About Us</a>
                                <a onClick={() => setMenu(false)} href="#footer" >Contact Us</a>
                                <div className="HeaderMainMenuMore">
                                    <a onClick={() => {
                                        setMenu(false)
                                        setBookAnAppointment(true)
                                    }}>Book An Appointment <img src="https://cdn-icons-png.flaticon.com/512/7322/7322265.png" alt="AAA Chimney Sweep Book An Appointment" /></a>
                                    <a href="tel:‪951-379-4048‬">CALL NOW! <img src="https://cdn-icons-png.flaticon.com/512/597/597177.png" alt="AAA Chimney Sweep Phone Number" /></a>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
            <div className="WebSite">
                <div id="mainbanner" className="MainBanner">
                    <div className="MainBannerInfo">
                        <h1>
                            AAA Chimney Sweep
                        </h1>
                        <h2>AAA Chimney Sweep – Your Trusted Chimney Cleaning Experts in Riverside, CA!</h2>
                        <a href="tel:‪951-379-4048‬">‪951-379-4048‬</a>
                    </div>
                    <div className="MainBannerImg">
                        <div className="MainBannerImgDark"></div>
                        <img src={img1} alt="CHIMNEY REPAIR SERVICE" />
                    </div>
                </div>
                <div id='services' className="Services">
                    <div className="Services1">
                        <img src={img2} alt="CHIMNEY SWEEPING & CLEANING" />
                        <h2>CHIMNEY SWEEPING & CLEANING IN Riverside, CA</h2>
                        <p>
                            At Riverside AAA Chimney Sweep, we pride ourselves on providing top-notch chimney sweeping and cleaning services to the residents of Riverside, CA. With years of experience and a commitment to excellence, we ensure your chimney is clean, safe, and functioning efficiently.

                            Why Chimney Sweeping is Essential

                            Regular chimney sweeping is crucial for maintaining a safe and efficient fireplace. Over time, chimneys can accumulate soot, creosote, and debris, which can become a fire hazard. Additionally, blockages caused by animal nests or leaves can obstruct proper ventilation, leading to dangerous carbon monoxide buildup in your home. Our professional chimney sweeping service helps prevent these risks, ensuring your chimney operates smoothly and safely.

                            Our Comprehensive Chimney Cleaning Services

                            Thorough Inspection: Before starting the cleaning process, our technicians conduct a detailed inspection of your chimney to identify any potential issues, such as cracks, blockages, or excessive creosote buildup.

                            Soot and Creosote Removal: Using specialized tools and techniques
                        </p>
                    </div>
                    <div className="Services2">
                        <img src={img4} alt="CHIMNEY INSPECTION SERVICE" />
                        <h2>CHIMNEY INSPECTION SERVICE IN Riverside, CA</h2>
                        <p>
                            Ensure the safety and efficiency of your chimney with Riverside AAA Chimney Sweep's professional chimney inspection service in Riverside, CA. Regular inspections are essential for detecting potential hazards, such as blockages, creosote buildup, and structural issues that could lead to fires or carbon monoxide leaks. Our certified technicians use the latest tools and techniques to thoroughly examine every aspect of your chimney, from the flue to the masonry, ensuring it operates safely and efficiently.

                            Our comprehensive chimney inspection includes:

                            Visual Examination: We conduct a detailed visual check of the chimney's exterior and interior, looking for any signs of wear and tear, damage, or obstructions.
                            Creosote Buildup Assessment: Creosote, a highly flammable byproduct of burning wood, can accumulate inside the chimney. We evaluate the level of buildup and recommend cleaning if necessary.
                            Structural Integrity Check: We inspect the chimney's structural components, including the crown, cap, and masonry, to ensure there are no cracks, gaps, or deterioration.
                            Smoke Chamber and Flue Inspection: Using specialized tools, we inspect the smoke chamber and flue for any blockages or damage that could impede proper ventilation.
                            Regular chimney inspections are crucial for maintaining a safe and efficient fireplace or heating system. Trust Riverside AAA Chimney Sweep to provide reliable and thorough inspections, keeping your home safe and your chimney in optimal condition. Schedule your chimney inspection today and experience peace of mind knowing your chimney is in expert hands.
                        </p>
                    </div>
                    <div className="Services3">
                        <img src={img5} alt="CHIMNEY REPAIR SERVICE" />
                        <h2>CHIMNEY REPAIR SERVICE</h2>
                        <p>
                            Chimney Repair Service in Riverside, CA
                            Keep your chimney in top condition with Riverside AAA Chimney Sweep's expert chimney repair services in Riverside, CA. Over time, chimneys can suffer from wear and tear, weather damage, and structural issues that compromise their safety and functionality. Our experienced technicians are equipped to handle a wide range of chimney repairs, ensuring your chimney remains safe, efficient, and aesthetically pleasing.

                            Our comprehensive chimney repair services include:

                            Masonry Repair and Rebuilding: Cracked or deteriorating bricks and mortar can weaken your chimney's structure. We provide professional masonry repair and rebuilding services to restore the integrity and appearance of your chimney.
                            Chimney Relining: A damaged or deteriorating flue liner can lead to poor ventilation and increased risk of chimney fires. We offer chimney relining services using high-quality materials to enhance safety and efficiency.
                            Crown and Cap Repair/Replacement: The chimney crown and cap protect your chimney from water damage and debris. We repair or replace damaged crowns and caps to ensure proper protection and functionality.
                            Leak Repair: Water leaks can cause significant damage to your chimney and surrounding areas. Our leak repair services address the root cause of the problem, preventing further damage and costly repairs.
                            Smoke Chamber Repair: A damaged smoke chamber can impede proper airflow and increase creosote buildup. We provide smoke chamber repair services to enhance ventilation and safety.
                            Firebox Repair: The firebox is subjected to intense heat and can develop cracks or other damage over time. Our firebox repair services ensure your fireplace remains safe and efficient.
                            At Riverside AAA Chimney Sweep, we are committed to providing top-notch chimney repair services that prioritize safety, quality, and customer satisfaction. Whether you need minor repairs or major restorations, our skilled technicians are here to help. Contact us today to schedule your chimney repair service and keep your chimney in excellent condition for years to come.
                        </p>
                    </div>
                </div>
                <div className="ServicesCallNow">
                    <div className="ServicesCallNowInfo">

                        <h1>
                            Eager to experience our top-notch service? Simply click the button below and give us a call today!
                        </h1>
                        <a href={`tel:‪951-379-4048‬`}>CALL NOW !</a>
                    </div>
                    <div className="ServicesCallNowImg">
                        <div className="ServicesCallNowImgDark"></div>
                        <img src={img1} alt="CHIMNEY SWEEPING & CLEANING" />
                    </div>
                </div>
                <div className="ServicesMore">

                    <h1>MORE OF OUR SERVICES:</h1>
                    <div className="ServicesMoreBoxMain">
                        <div className="ServicesMoreBoxMainLeft">
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Cap Repair Riverside, CA" />
                                <a href="/Chimney-Cap-Repair">Chimney Cap Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Cleaning Riverside, CA" />
                                <a href="/Chimney-Cleaning">Chimney Cleaning Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Construction Riverside, CA" />
                                <a href="/Chimney-Construction">Chimney Construction Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Crown Repair Riverside, CA" />
                                <a href="/Chimney-Crown-Repair">Chimney Crown Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Flue Installation Riverside, CA" />
                                <a href="/Chimney-Flue-Installation">Chimney Flue Installation Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Flue Repair Riverside, CA" />
                                <a href="/Chimney-Flue-Repair">Chimney Flue Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Inspection Riverside, CA" />
                                <a href="/Chimney-Inspection">Chimney Inspection Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Installation Riverside, CA" />
                                <a href="/Chimney-Installation">Chimney Installation Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Insulation Riverside, CA" />
                                <a href="/Chimney-Insulation">Chimney Insulation Riverside, CA</a>
                            </div>
                        </div>
                        <div className="ServicesMoreBoxMainMiddle">
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Cracks Repair Riverside, CA" />
                                <a href="/Chimney-Cracks-Repair">Chimney Cracks Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Firebox Paint Riverside, CA" />
                                <a href="/Chimney-Firebox-Paint">Chimney Firebox Paint Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Firebox Cleaining Riverside, CA" />
                                <a href="/Chimney-Firebox-Cleaining">Chimney Firebox Cleaining Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Firebox Inspection Riverside, CA" />
                                <a href="/Chimney-Firebox-Inspection">Chimney Firebox Inspection Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Best Company Riverside, CA" />
                                <a href="/Chimney-Best-Company">Chimney Best Company Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Firebox Inspection Riverside, CA" />
                                <a href="/Firebox-Inspection">Firebox Inspection Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Firebox Sweeping Riverside, CA" />
                                <a href="/Firebox-Sweeping">Firebox Sweeping Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Fireplace Inspection Riverside, CA" />
                                <a href="/Fireplace-Inspection">Fireplace Inspection Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Firepalce Repair Riverside, CA" />
                                <a href="/Firepalce-Repair">Firepalce Repair Riverside, CA</a>
                            </div>
                        </div>
                        <div className="ServicesMoreBoxMainRight">
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Liner Repair Riverside, CA" />
                                <a href="/Chimney-Liner-Repair">Chimney Liner Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Maintenance Riverside, CA" />
                                <a href="/Chimney-Maintenance">Chimney Maintenance Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Pointing Riverside, CA" />
                                <a href="/Chimney-Pointing">Chimney Pointing Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Rain Cap Installation Riverside, CA" />
                                <a href="/Chimney-Rain Cap-Installation">Chimney Rain Cap Installation Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Repair Riverside, CA" />
                                <a href="/Chimney-Repair">Chimney Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Restoration Riverside, CA" />
                                <a href="/Chimney-Restoration">Chimney Restoration Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Flashing Repair Riverside, CA" />
                                <a href="/Chimney-Flashing-Repair">Chimney Flashing Repair Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Liner Installation Riverside, CA" />
                                <a href="/Chimney-Liner-Installation">Chimney Liner Installation Riverside, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Damper Repair Riverside, CA" />
                                <a href="/Chimney-Damper-Repair">Chimney Damper Repair Riverside, CA</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="BeforeAndAfter">
                    <h1>Why Choose AAA Chimney Sweep?</h1>
                    <img src={img2} alt="Is it time to clean your chimney?" />
                    <h2>There are several factors that increase the risk of chimney fires. These include:</h2>
                    <h3>
                        At AAA Chimney Sweep, we are dedicated to providing top-quality chimney services that ensure the safety and efficiency of your home. Here are a few reasons why you should choose us for all your chimney needs:
                        <br />
                        Experienced Professionals: Our team consists of highly trained and certified technicians with years of experience in chimney sweeping, inspection, and repair. We stay updated with the latest industry standards and techniques to deliver the best service possible.
                        <br />

                        Comprehensive Services: We offer a wide range of chimney services, including sweeping, cleaning, inspection, repair, and more. Whether you need a routine cleaning or a major repair, we have the expertise to handle it all.
                        <br />
                        <br />

                        Safety First: Your safety is our top priority. We conduct thorough inspections and use high-quality materials for all repairs to ensure your chimney is in excellent condition and functioning safely.
                        <br />

                        Customer Satisfaction: We are committed to providing exceptional customer service. From the moment you contact us to the completion of the job, we strive to exceed your expectations and ensure you are completely satisfied with our work.
                        <br />
                        <br />

                        Competitive Pricing: We offer high-quality services at competitive prices. Our transparent pricing ensures you know exactly what to expect without any hidden fees.

                        Locally Owned and Operated: As a local business, we take pride in serving our community. We understand the specific needs of homeowners in Riverside, CA, and are dedicated to providing personalized service tailored to your needs.

                        Reliable and Trustworthy: We have built a reputation for being reliable and trustworthy. Our customers rely on us for our honesty, integrity, and dedication to quality.

                        Choose AAA Chimney Sweep for all your chimney needs and experience the peace of mind that comes with knowing your home is in good hands. Contact us today to schedule your service and discover why we are Riverside's preferred chimney sweep company.
                    </h3>

                </div>
                <div className="BookAnAppointmentMain">
                    <div className="BookAnAppointmentMainLeft">
                        <h1>Schedule Your Chimney Service with Ease</h1>
                        <p>
                            At Riverside AAA Chimney Sweep, we are dedicated to making the process of booking your chimney services as convenient as possible. Whether you need chimney sweeping, cleaning, inspection, or repair, scheduling an appointment with us is easy and straightforward. You can book your service over the phone or online, ensuring a hassle-free experience.

                        </p>
                    </div>
                    <div className="BookAnAppointmentMainRight">
                        <div className="BookAnAppointmentMainRightInfo">
                            <h1>Book an Appointment Online Now...</h1>
                            <button onClick={() => setBookAnAppointment(true)}>BOOK NOW</button>
                        </div>

                    </div>
                    <div className="BookAnAppointmentMainRightImg">
                        <div className="MainBannerImgDark"></div>
                        <img src={img5} alt="Is it time to clean your chimney?" />
                    </div>
                    {
                        BookAnAppointment ?
                            <BookAnAppointmentMainComponent setBookAnAppointment={setBookAnAppointment} CustInfo={CustInfo} />
                            :
                            null}
                </div>
                <div id='whyus' className="WhyUs">
                    {/* <img src="https://i.insider.com/61dca5f64ede320018b6e5a6?width=900&format=jpeg&auto=webp" alt="" /> */}
                    <div className="WhyUsTitle">
                        <h1>Why Choose AAA Chimney Sweep?</h1>
                        <p>At Riverside AAA Chimney Sweep, we are dedicated to providing top-quality chimney services to ensure the safety and efficiency of your home. Here are a few reasons why you should choose us for all your chimney needs:</p>
                    </div>
                    <div className="WhyUsList">
                        <div className="WhyUsLkistBox">
                            <div className="WhyUsLkistBoxLeft">
                                <img src="https://cdn-icons-png.flaticon.com/512/2780/2780505.png" alt="Chimney Sweep Near Me" />
                            </div>
                            <div className="WhyUsLkistBoxRight">
                                <h2>Experienced Professionals</h2>
                                <h3>Our team consists of highly trained and certified technicians with years of experience in chimney sweeping, inspection, and repair. We stay updated with the latest industry standards and techniques to deliver the best service possible.</h3>
                            </div>
                        </div>
                        <div className="WhyUsLkistBox">
                            <div className="WhyUsLkistBoxLeft">
                                <img src="https://cdn-icons-png.flaticon.com/512/2212/2212838.png" alt="Chimney Sweep Near Me" />
                            </div>
                            <div className="WhyUsLkistBoxRight">
                                <h2>Comprehensive Services</h2>
                                <h3>We offer a wide range of chimney services, including sweeping, cleaning, inspection, repair, and more. Whether you need a routine cleaning or a major repair, we have the expertise to handle it all.</h3>
                            </div>
                        </div>
                        <div className="WhyUsLkistBox">
                            <div className="WhyUsLkistBoxLeft">
                                <img src="https://cdn-icons-png.flaticon.com/512/807/807303.png" alt="Chimney Sweep Near Me" />
                            </div>
                            <div className="WhyUsLkistBoxRight">
                                <h2>Safety First</h2>
                                <h3>Your safety is our top priority. We conduct thorough inspections and use high-quality materials for all repairs to ensure your chimney is in excellent condition and functioning safely.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='footer' className="Footer">
                <div className="FooterFormMain">
                    <div className="FooterFormTitle">
                        <h1>CONTACT US FOR MORE DETAILS</h1>
                    </div>
                    <div className="FooterForm">

                        <form className="contact-form" onSubmit={sendEmail}>
                            <h3>Name</h3>
                            <input type='text' name="Name" />
                            <h3>Phone</h3>
                            <input type='tel' name="Phone" />
                            <h3>Location (Address)</h3>
                            <input type='text' name="Location" />
                            <h3>Message</h3>
                            <input type='text' name="Message" />

                            <input type="hidden" name="Company_From" defaultValue={`AAA Chimney Sweep`} />

                            {
                                FormSubmiting ?
                                    <button class="buttonload">
                                        <i class="fa fa-refresh fa-spin"></i>Loading
                                    </button>
                                    :

                                    <input type="submit" value="SEND" />
                            }
                        </form>
                    </div>
                </div>
                <div className="FooterContactUs">
                    <div className="FooterContactUsTitle">
                        <h2>CONTACT DETAILS</h2>
                        <h3>If you have any questions at all, please feel free to contact us. We are available via phone or email. You can also use a quick contact form below or visit us personally.</h3>
                    </div>
                    <div className="FooterContactUsInfo">
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/597/597177.png" alt={`AAA Chimney Sweep Phone Number ‪951-379-4048‬`} />
                            <h2>Phone Number: <a href="tel:951-379-4048">951-379-4048</a></h2>
                        </div>
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/646/646135.png" alt="AAA Chimney Sweep Email | info@riverside-aaachimneysweep.coml" />
                            <h2>Email: <a href="mailto:info@riverside-aaachimneysweep.com">info@riverside-aaachimneysweep.com</a></h2>
                        </div>
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/1006/1006771.png" alt="AAA Chimney Sweep Website | www.riverside-aaachimneysweep.com" />
                            <h2>Website: <a href="https://www.riverside-aaachimneysweep.com/">www.riverside-aaachimneysweep.com</a></h2>
                        </div>

                    </div>
                    <div className="FooterContactUsAbout">
                        <h2>
                            Our Customers Rates: 5.0 Stars
                        </h2>
                        <h3>
                            © Copyright 2022 AAA Chimney Sweep. All Right Reserved. <span onClick={() => navigate('/sitemap')}>Sitemap</span>
                        </h3>
                    </div>
                </div>

            </div>
            {
                !BookAnAppointment ?
                    <div className="CallNow">
                        {/* <h1>CALL NOW !</h1> */}
                        <a href="tel:‪951-379-4048‬">CALL NOW !</a>
                    </div>
                    :
                    null
            }
            {
                !BookAnAppointment ?
                    <div className="BookAnAppointmentButton" onClick={() => setBookAnAppointment(true)}>
                        <h1>BOOK ONLINE</h1>
                    </div>
                    :
                    null
            }
        </div>
    );
}

export default HomePage;
